import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Button,
  ButtonBlock,
  List,
  ListItem,
  TextBlock,
  TextField,
  Font,
} from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import {
  type EligiblePacket,
  type EligiblePacketScan,
  useDeliveryState,
  useDeliveryActions,
} from './DeliveryContext'
import styles from './MissingLookupCodesTable.module.scss'
import { usesScanLookupCodes } from './utils'

const MissingLookupCodeTableRow: React.FC<{
  packet: EligiblePacket
  scan: EligiblePacketScan
  delivery: { id: number }
}> = ({ packet, scan, delivery }) => {
  const { t } = useTranslation()
  const { updateScanLookupCodeErrors } = useDeliveryState()
  const { updateScanLookupCode } = useDeliveryActions()
  const [lookupCode, setLookupCode] = useState<string | number>()

  return (
    <>
      <TextBlock>
        <Font.Copy variant="medium">
          Scan #{scan.scan_number} - {scan.display_name || 'Data Pending'}
        </Font.Copy>
        <Font.Copy variant="secondary">{packet.scan_name}</Font.Copy>
      </TextBlock>
      <TextField
        icon={undefined}
        label="Lookup Code"
        value={lookupCode ?? ''}
        onChange={setLookupCode}
        error={!!updateScanLookupCodeErrors.lookup_code}
        errorMessage={updateScanLookupCodeErrors.lookup_code}
      />
      <ButtonBlock justify="right">
        <Link
          to={`/collect/voter_registration/shifts/${scan.shift.id}/packet/scans/${scan.id}?source=deliveries&sourceId=${delivery.id}`}
        >
          <Button variant="secondary">{t('Inspect')}</Button>
        </Link>
        <Button
          type="submit"
          disabled={!lookupCode}
          onClick={() =>
            lookupCode && updateScanLookupCode(scan, lookupCode.toString())
          }
        >
          Submit
        </Button>
      </ButtonBlock>
    </>
  )
}

export const MissingLookupCodesTable: React.FC = () => {
  const { currentDelivery, filteredPackets } = useDeliveryState()
  const scansMissingLookupCode = usesScanLookupCodes(currentDelivery)
    ? filteredPackets.flatMap(packet =>
        packet.forms
          .filter(
            scan =>
              !scan.lookup_code &&
              !scan.visual_review_responses.some(
                response => response.implies_not_form
              )
          )
          .map(scan => ({ packet, scan }))
      )
    : []

  return scansMissingLookupCode.length > 0 ? (
    <>
      <TextBlock>
        <Font.Label variant="section" className={styles.warning}>
          Missing Lookup Codes
        </Font.Label>
      </TextBlock>
      <List
        loading={false}
        emptyMessage=""
        itemData={scansMissingLookupCode}
        render={({ packet, scan }) => (
          <ListItem key={(scan as { id: number }).id}>
            <MissingLookupCodeTableRow
              packet={packet as EligiblePacket}
              scan={scan as EligiblePacketScan}
              delivery={currentDelivery}
            />
          </ListItem>
        )}
      />
    </>
  ) : null
}
