import { permissionIds as regReportPermissionIds } from 'registrationReports/reportsConfig'
import { permissionIds as qcReportPermissionIds } from 'qualityControlReports/reportsConfig'
import { permissionIds as phoneBankReportPermissionIds } from 'phoneBankReports/configs'
import { permissionIds as weeklyReportPermissionIds } from 'weeklyReports/config'
import { permissionIds as petitionReportPermissionIds } from 'petitionReports/configs'

export const BASIC_ABILITIES = [
  { value: 'view', label: 'Can view' },
  { value: 'modify', label: 'Can edit' },
  { value: 'remove', label: 'Can delete' },
]

const buildReportPermissionData = (permissionIds, blocks) =>
  permissionIds.map(id => ({
    id,
    blocks,
    abilities: [
      { value: 'view', label: 'Can view' },
      { value: 'download', label: 'Can download csv' },
      { value: 'sql', label: 'Can download SQL' },
    ],
  }))

export const REPORT_PERMISSION_DATA = [
  ...buildReportPermissionData(regReportPermissionIds, [
    'reporting',
    'voter_registration',
  ]),
  ...buildReportPermissionData(qcReportPermissionIds, [
    'reporting',
    'quality_control',
  ]),
  ...buildReportPermissionData(phoneBankReportPermissionIds, [
    'reporting',
    'phone_banking',
  ]),
  ...buildReportPermissionData(weeklyReportPermissionIds, [
    'reporting',
    'quality_control',
  ]),
  ...buildReportPermissionData(petitionReportPermissionIds, [
    'reporting',
    'petitions',
  ]),
  ...buildReportPermissionData(['catalist_reports'], ['reporting', 'catalist']),
]

export const FIELD_MANAGEMENT_PERMISSION_DATA = [
  {
    id: 'field_management/goal',
    label: 'Goals',
    blocks: ['field_management'],
    abilities: BASIC_ABILITIES,
  },
  {
    id: 'field_management/projection',
    label: 'Projections',
    blocks: ['field_management'],
    abilities: BASIC_ABILITIES,
  },
]

const PERMISSION_DATA = [
  ...REPORT_PERMISSION_DATA,
  ...FIELD_MANAGEMENT_PERMISSION_DATA,
  {
    id: 'petition',
    label: 'Petitions',
    blocks: ['petitions'],
    abilities: [
      ...BASIC_ABILITIES,
      { value: 'unassign', label: 'Can unassign' },
    ],
  },
  {
    id: 'petition_signature',
    label: 'Petition signatures',
    blocks: ['petitions'],
    abilities: BASIC_ABILITIES,
  },
  {
    id: 'check_in',
    label: 'Check-in',
    blocks: ['organize'],
    abilities: [
      { value: 'participate', label: 'Can participate' },
      { value: 'manage', label: 'Can manage' },
    ],
  },
  {
    id: 'user',
    label: 'User settings',
    all: true,
    abilities: [
      ...BASIC_ABILITIES,
      { value: 'impersonate', label: 'Can impersonate' },
      { value: 'disable', label: 'Can disable' },
    ],
  },
  {
    id: 'absentee_ballot_request_form',
    label: 'Absentee ballot request form',
    blocks: ['absentee_ballot_requests'],
    abilities: BASIC_ABILITIES,
  },
  {
    id: 'campaign',
    label: 'Campaign',
    blocks: ['organize'],
    abilities: BASIC_ABILITIES,
  },
  {
    id: 'canvasser',
    label: 'Canvasser',
    blocks: ['voter_registration'],
    abilities: BASIC_ABILITIES,
  },
  {
    id: 'collection',
    label: 'Collection',
    blocks: ['turn_in_tracking'],
    abilities: BASIC_ABILITIES,
  },
  {
    id: 'dashboard_layout',
    label: 'Dashboard layout',
    all: true,
    abilities: BASIC_ABILITIES,
  },
  {
    id: 'dashboard_widget',
    label: 'Dashboard widget',
    all: true,
    abilities: [
      ...BASIC_ABILITIES,
      { value: 'view_usage', label: 'View usage' },
    ],
  },
  {
    id: 'delivery',
    label: 'Delivery',
    blocks: ['voter_registration'],
    abilities: [...BASIC_ABILITIES, { value: 'complete', label: 'Complete' }],
  },
  {
    id: 'document',
    label: 'Document',
    blocks: ['documents'],
    abilities: BASIC_ABILITIES,
  },
  {
    id: 'email_template',
    label: 'Email template',
    blocks: ['engage'],
    abilities: BASIC_ABILITIES,
  },
  {
    id: 'event',
    label: 'Event',
    blocks: ['events'],
    abilities: BASIC_ABILITIES,
  },
  {
    id: 'event_attendee',
    label: 'Event attendee',
    blocks: ['events'],
    abilities: BASIC_ABILITIES,
  },
  {
    id: 'import',
    label: 'Import',
    blocks: ['data_sources'],
    abilities: BASIC_ABILITIES,
  },
  {
    id: 'list',
    label: 'List',
    blocks: ['organize'],
    abilities: BASIC_ABILITIES,
  },
  {
    id: 'list_folder',
    label: 'List folder',
    blocks: ['organize'],
    abilities: BASIC_ABILITIES,
  },
  {
    id: 'location',
    label: 'Location',
    blocks: ['events', 'voter_registration'],
    abilities: BASIC_ABILITIES,
  },
  {
    id: 'meeting',
    label: 'Meeting',
    blocks: ['events'],
    abilities: BASIC_ABILITIES,
  },
  {
    id: 'note',
    label: 'Note',
    blocks: ['organize'],
    abilities: BASIC_ABILITIES,
  },
  {
    id: 'organization',
    label: 'Organization',
    blocks: ['organize'],
    abilities: BASIC_ABILITIES,
  },
  {
    id: 'organization_membership',
    label: 'Organization membership',
    blocks: ['organize'],
    abilities: BASIC_ABILITIES,
  },
  {
    id: 'person',
    label: 'Person',
    blocks: ['organize'],
    abilities: [
      ...BASIC_ABILITIES,
      { value: 'edit_special_fields', label: 'Can edit special fields' },
    ],
  },
  {
    id: 'phone_banking/call',
    label: 'Phone banking calls',
    blocks: ['phone_banking'],
    abilities: BASIC_ABILITIES,
  },
  {
    id: 'phone_banking/phone_bank',
    label: 'Phone banking phone banks',
    blocks: ['phone_banking'],
    abilities: BASIC_ABILITIES,
  },
  {
    id: 'phone_banking/question',
    label: 'Phone banking questions',
    blocks: ['phone_banking'],
    abilities: BASIC_ABILITIES,
  },
  {
    id: 'phone_banking/script',
    label: 'Phone banking scripts',
    blocks: ['phone_banking'],
    abilities: BASIC_ABILITIES,
  },
  {
    id: 'phone_banking/script_text',
    label: 'Phone banking script texts',
    blocks: ['phone_banking'],
    abilities: BASIC_ABILITIES,
  },
  {
    id: 'phone_banking/session',
    label: 'Phone banking sessions',
    blocks: ['phone_banking'],
    abilities: [...BASIC_ABILITIES, { value: 'operator', label: 'Operator' }],
  },
  {
    id: 'shift',
    key: 'quality_control/review',
    label: 'Quality control review',
    blocks: ['voter_registration'],
    abilities: [
      { value: 'visual_qc', label: 'Visual QC' },
      { value: 'phone_verification', label: 'Phone verification' },
    ],
  },
  {
    id: 'quality_control/comment',
    label: 'Quality control comment',
    blocks: ['quality_control'],
    abilities: BASIC_ABILITIES,
  },
  {
    id: 'quality_control/flag',
    label: 'Quality control flag',
    blocks: ['quality_control'],
    abilities: [
      ...BASIC_ABILITIES,
      { value: 'start', label: 'Start' },
      { value: 'review', label: 'Review' },
      { value: 'complete', label: 'Complete' },
      { value: 'reopen', label: 'Reopen' },
    ],
  },
  {
    id: 'quality_control/schedule',
    label: 'Quality control schedule',
    blocks: ['quality_control'],
    abilities: BASIC_ABILITIES,
  },
  {
    id: 'quality_control/phone_verification_script',
    label: 'Quality control phone verification script',
    blocks: ['quality_control'],
    abilities: BASIC_ABILITIES,
  },
  {
    id: 'quality_control/phone_verification_call',
    label: 'Quality control phone verification calls',
    blocks: ['quality_control'],
    abilities: [
      { value: 'reports', label: 'View call reports' },
      { value: 'modify', label: 'Make phone verification calls' },
    ],
  },
  {
    id: 'twilio_call',
    label: 'Quality control phone verification call recordings',
    blocks: ['quality_control'],
    abilities: [{ value: 'view', label: 'View call recordings' }],
  },
  {
    id: 'registrant_match',
    label: 'Registrant match',
    blocks: ['voter_registration'],
    abilities: BASIC_ABILITIES,
  },
  {
    id: 'registration_form',
    label: 'Registration form',
    blocks: ['voter_registration'],
    abilities: BASIC_ABILITIES,
  },
  {
    id: 'relationship',
    label: 'Relationship',
    blocks: ['organize'],
    abilities: BASIC_ABILITIES,
  },
  {
    id: 'report',
    label: 'Report',
    blocks: ['reporting'],
    abilities: BASIC_ABILITIES,
  },
  {
    id: 'role',
    label: 'Role',
    all: true,
    abilities: BASIC_ABILITIES,
  },
  {
    id: 'scheduled_export',
    label: 'Scheduled export',
    blocks: ['data_sources'],
    abilities: BASIC_ABILITIES,
  },
  {
    id: 'shift',
    label: 'Shift',
    blocks: ['voter_registration'],
    abilities: [
      { value: 'view', label: 'Can view' },
      {
        value: 'modify',
        label: 'Can edit',
        requires: [
          { permission: 'canvasser', value: 'view' },
          { permission: 'location', value: 'view' },
          { permission: 'turf', value: 'view' },
        ],
      },
      { value: 'remove', label: 'Can delete' },
      { value: 'data_entry', label: 'Data entry' },
      { value: 'unlock', label: 'Unlock' },
    ],
  },
  {
    id: 'team',
    label: 'Team',
    blocks: ['organize'],
    abilities: BASIC_ABILITIES,
  },
  {
    id: 'team_membership',
    label: 'Team membership',
    blocks: ['organize'],
    abilities: BASIC_ABILITIES,
  },
  {
    id: 'tenant',
    label: 'Tenant',
    all: true,
    abilities: BASIC_ABILITIES,
  },
  {
    id: 'turf',
    label: 'Turf',
    all: true,
    abilities: BASIC_ABILITIES,
  },
  {
    id: 'turf_level',
    label: 'Turf level',
    all: true,
    abilities: BASIC_ABILITIES,
  },
  {
    id: 'volunteership',
    label: 'Volunteership',
    blocks: ['organize'],
    abilities: BASIC_ABILITIES,
  },
  {
    id: 'vote',
    label: 'Vote',
    blocks: ['voter_registration'],
    abilities: BASIC_ABILITIES,
  },
  {
    id: 'registration_form',
    label: 'Voter registration scan',
    blocks: ['voter_registration'],
    abilities: BASIC_ABILITIES,
  },
  {
    id: 'voter_registration_scan_batch',
    label: 'Voter registration scan batch',
    blocks: ['voter_registration'],
    abilities: BASIC_ABILITIES,
  },
]

export const PERMISSION_OPTIONS = PERMISSION_DATA.map(permission => ({
  ...permission,
  abilities: permission.abilities.map(ability => ({
    ...ability,
    id: `${permission.id}.${ability.value}`,
    permission: {
      id: permission.id,
      label: permission.label,
    },
  })),
}))
